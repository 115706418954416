import { Channel } from '~/gql/graphql';

// partnerLockIn対象が増えたらここに足す
const partnerLockInChannels = [
  Channel.RakutenInHouseAdvisor,
  Channel.ZaikeiJutakuKinyu,
];

export const usePartnerLockIn = (
  viewerChannelRef: RefOrGetterFunction<Nullish<Channel>>
) => {
  if (import.meta.server) {
    console.error('usePartnerLockInをサーバーサイドで〜呼ばないでください〜🤪');
    throw new Error(
      'usePartnerLockInをサーバーサイドで〜呼ばないでください〜🤪'
    );
  }
  const { channel } = useSessionChannel();

  const isPartnerLockIn = computed(() =>
    partnerLockInChannels.some(
      (value) => value === channel.value || value === toValue(viewerChannelRef)
    )
  );

  return { isPartnerLockIn };
};
